<template>
  <div class="container">
    <div class="m-auto">
      <h3 class="mt-5">Survey Logs for Survey {{ code }} </h3>
      <div class="row">
        <!--  Menu bar-->
        <div class="stat-card-actions m-5 float-right">
          <ul class="nav">
            <li  class="me-1">
              <button :class="columns ? 'active' : ''"
                      class="custom-btn custom-btn-outline custom-btn-xs"
                      title="Switch to list view" @click="setColumns()">
                <font-awesome-icon icon="list"/>
                <span v-if="table"  class="d-none d-lg-inline-block">Switch to List View</span>
                <span class="d-lg-none d-inline-block">List View</span>
              </button>
            </li>
            <li class="me-1">
              <button  :class="table ? 'active' : ''"
                       class="custom-btn custom-btn-outline custom-btn-xs"
                       title="Switch to table view" @click="setTable()">
                <font-awesome-icon icon="table"/>
                <span v-if="columns"  class="d-none d-lg-inline-block">Switch to Table View</span>
                <span class="d-lg-none d-inline-block">Table View</span>
              </button>
            </li>
            <li class="me-1">
              <button class="custom-btn custom-btn-outline custom-btn-success custom-btn-xs"
                      @click="exportCsv()"
                      title="Download Logs in a CSV document">
                <font-awesome-icon icon="arrow-down"/>
                CSV
              </button>
            </li>
            <li class="me-1">
              <button class="custom-btn custom-btn-outline custom-btn-secondary custom-btn-xs"
                      @click="firePrint()"
                      :title="'Download logs in a PDF document'">
                <font-awesome-icon icon="arrow-down"/>
                PDF
              </button>
            </li>
            <li class="me-1">
              <b-form-input v-model="filter_search" class="form-control d-inline-block"
                            placeholder="Search by Name or Code..." type="search"/>
            </li>
            <li class="">
              <select class="form-select" v-model="filter_date">
                <option value="">Select Period</option>
                <option value="Last 1 Month">Last 1 Month</option>
                <option value="Last 3 Months">Last 3 Months</option>
              </select>
            </li>
          </ul>
        </div>
        <div v-if="wait && responses == 0"
             class="alert alert-info">
          <h6 class="mb-1"><span class="font-weight-semibold">Just a second...</span></h6>
          <p class="mb-0">Retrieving Responses Logs.</p>
        </div>
      </div>

      <div class="row">
        <div v-if="columns" class="row columns list-view">
          <div  class="col-12 col-md-8 overflow-auto h-100">
            <!--            <b-form-select class="form-select float-right"  v-model="selected" @click.prevent="response = i">-->
            <!--              <option selected value="">Select Response</option>-->
            <!--              <option  v-for="i in responses.data" :key="i.code" :value="i"-->
            <!--                       :class="i.id == log.id  ? 'active' : ''">-->
            <!--                {{ i.code }} - {{ i.user.name }}<br/>-->
            <!--                Date Filled - {{ moment(i.created_at).format("DD/MM/YYYY, HH:mm A") }}-->
            <!--              </option>-->
            <!--            </b-form-select>-->
            <!-- Responses With Logs -->
            <div class="mt-3 p-4">
              <h5 class="mb-2">Responses Details</h5>
              <div v-for="i in responses.data" :key="i.code" :value="i" class="">
                <div @click.prevent="response = i" style="background: #f9f9f9">
                  <a class="card p-4" :class="i.id == log.id ? 'selected' : ''" style="cursor: pointer">
                    <span class="user-id">Sender - {{ i.user.name }}</span><br>
                    <span class="user-id">Response Code - {{ i.code }}</span><br>
                    <span class="user-id">Date Filled - {{ moment(i.created_at).format("DD/MM/YYYY, HH:mm A") }}</span>
                  </a>
                </div>
                <br>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4 logs-column">
            <template v-if="logs.length">
              <h5 class="mb-2">Log Details</h5>
              <div class="bg-white p-4 border-radius-10">
                <ul v-for="log in logs" :key="log.id" class="list-unstyled">
                  <li>
                    <div class="d-inline text-black-80 p-4">From - <strong>{{ log.sender.name }}</strong></div><br>
                    <div class="d-inline text-black-80 p-4">To - <strong>{{ log.receiver.name }}</strong></div><br>
                    <div class="d-inline text-black-80 p-4">Location - <strong>{{ log.location }}</strong></div><br>
                    <div class="d-inline p-4">Time - <strong>{{ moment(log.created_at).format("DD/MM/YYYY, HH:mm A") }}</strong></div>
                  </li>
                  <hr>
                </ul>
              </div>
            </template>
          </div>
        </div>
        <div v-if="table" class="table table-view">
          <b-table-simple
              responsive
              striped
              class="responses-table">
            <b-thead>
              <b-tr class="bg-white">
                <b-th class="bg-white hide-on-print">
                  <input type="checkbox">
                </b-th>
                <b-th class="bg-white">Response Code</b-th>
                <b-th class="bg-white">From</b-th>
                <b-th class="bg-white">To</b-th>
                <b-th class="bg-white">Location(Geo)</b-th>
                <b-th class="bg-white">Date</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="log in logs" :key="log.id">
                <b-td class="hide-on-print"><input type="checkbox" number></b-td>
                <b-td >{{ log.code }}</b-td>
                <b-td>{{ log.sender.name }}</b-td>
                <b-td>{{ log.receiver.name }}</b-td>
                <b-td>{{ log.location }}</b-td>
                <b-td>{{ moment(log.created_at).format("DD/MM/YYYY, HH:mm A") }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
      <!-- Pagination -->
      <Pagination
          :limit="1"
          :showDisabled="true"
          :data="responses"
          @pagination-change-page="loadRespondents">
      </Pagination>
    </div>
  </div>
</template>

<script>
import Pagination from "laravel-vue-pagination";

export default {
  name: "SurveyLogs",
  components: { Pagination },
  props: ['code', 'auth'],
  data() {
    return {
      log: '',
      logs: [],
      responses: {data: []},
      response: null,
      columns: true,
      table: false,
      selected: [],
      filter_search: ''
    }
  },
  mounted() {
    this.loadRespondents();
  },
  watch: {
    response() {
      this.loadLogs()
    },
    selected_res() {
      this.response = this.selected
    },
    filter_search() {
      this.loadRespondents()
    }
  },
  methods: {
    loadRespondents() {
      this.clear()
      this.wait = true
      this.success = "Loading Logs, please wait..."
      this.$http.get(`/api/logs/respondents/${this.code}/?search=` + this.filter_search).then(response => {
        this.responses = response.data.data
      }).catch(error => {
        this.showError(error)
      })
    },
    loadLogs() {
      this.$http.get(`/api/logs/${this.response.code}`).then(response => {
        this.logs = response.data.data
      }).catch(error => {
        this.showError(error)
      })
    },
    getFirstLetters(str) {
      const firstLetters = str
          .split(' ')
          .map(word => word[0])
          .join('');

      return firstLetters;
    },
    exportCsv() {

    },

    firePrint() {
      let config = {
        responseType: 'blob',
      }
      this.$http.get(`/api/export/csv/${this.code}`, config).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'file.pdf');
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    setColumns() {
      this.columns = true
      this.table = false
    },
    setTable() {
      this.table = true
      this.columns = false
    }
  }
}

</script>

<style scoped>
.logs-column{

}
</style>